import React from "react";
import AccordionCard from "../components/faq/AccordionCard";
import teamImage from "../assets/images/team.jpeg";
import Titlelink from "../components/others/Titlelink";

function FAQ(props) {
  return (
    <div className="bg-black text-white px-[100px] pt-12">
      <div className="grid grid-cols-2 gap-8 mb-20 ">
        <div>
          {/* <Titlelink
            text1="Did you see our new article?"
            text2="Check it out @ Medium"
          /> */}
          <h5 className="text-6xl font-medium opacity-[0.88] capitalize mb-6">
            Frequently asked <span className="gradient-text"> questions </span>
          </h5>
          <p className="opacity-[0.5] flex items-end">
            Ask all your questions and more...
          </p>
        </div>

        <p className="opacity-[0.5] flex items-end"></p>
      </div>
      <AccordionCard />
      <section className="mt-20">
        <div className="grid grid-cols-2 gap-24">
          <div>
            <p className="opacity-[0.5] mb-6 gradient-text">
              Have more pressing questions? Ask us here
            </p>
            <div>
              <input
                className="block w-full bg-[#0c0c0c] text-white rounded py-3 px-4 mb-3 leading-tight"
                id="grid-first-name"
                type="text"
                placeholder="Email"
              />
              <textarea
                className="block w-full bg-[#0c0c0c] h-[150px] text-white rounded py-3 px-4 mb-3 leading-tight"
                placeholder="Message"
              ></textarea>
              <button className="text-[13px] font-medium bg-transparent mt-4 bg-red rounded-[4px]  px-12 py-3 ">
               Send Now
              </button>
            </div>
          </div>
          <div>
            <h2 className="text-5xl opacity-[0.88] font-medium mb-7">
              Ask our customer support
            </h2>
            <div className="flex">
              <img
                src={teamImage}
                alt="teamImage"
                className="h-[110px] w-[110px] rounded-full object-cover"
              />
              <img
                src={teamImage}
                alt="teamImage"
                className="h-[110px] w-[110px] rounded-full object-cover ml-[-50px]"
              />
              <img
                src={teamImage}
                alt="teamImage"
                className="h-[110px] w-[110px] rounded-full object-cover ml-[-50px]"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FAQ;
