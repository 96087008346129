import React from "react";
import logo from "../../assets/images/anamologo.png";
import usprotech from "../../assets/images/partners/usprotech.png";

export default function Footer() {
  const footerLinks = {
    company: [
      { title: "Home", link: "/" },
      { title: "About", link: "/about" },
      { title: "Services", link: "/services" },
    ],
    legal: [
      { title: "Terms of Service", link: "" },
      { title: "Privacy Policy", link: "" },
      { title: "Cookie Policy", link: "" },
    ],
    contact: [
      { title: "info@anamo.io", link: "info@anamo.io" },
      { title: "(949) 629-3900", link: "" },
      { title: "Las Vegas, Nevada - USA", link: "" },
    ],
  };
  return (
    <div className=" bg-black text-white px-10 md:px-28 pb-12 font-neue">
      <div className="pt-10">
        <div className="flex flex-col md:flex-row justify-between items-start md-10 md:mb-16">
          <div className="flex flex-col md:w-[50%] ">
            <div>
              <img
                src={logo}
                alt="anamologo"
                className="h-[15px] object-cover"
              />
            </div>
            <p className="text-sm opacity-[0.6] mt-5 lg:w-[440px]">
              Protect your systems from potential cyber attacks by proactively
              identifying and addressing software and hardware vulnerabilities
              with our comprehensive vulnerability management solutions.
            </p>
            <div className="mt-4">
              <a href="https://www.usprotech.com/" target="blank">
                <img
                  src={usprotech}
                  alt="anamologo"
                  className="h-[50px] object-cover"
                />
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 mt-10 md:mt-0">
            {/* <div>
              <h5 className="text-sm font-bold mb-5">Company</h5>
              <ul className="space-y-3">
                {footerLinks.company.map((link) => (
                  <li className="text-sm opacity-[0.5]">
                    <a href={link.link}>
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div> */}
            {/* <div>
              <h5 className="text-sm font-bold mb-5">Legal</h5>
              <ul className="space-y-3">
                {footerLinks.legal.map((link) => (
                  <li className="text-sm opacity-[0.5]">{link.title}</li>
                ))}
              </ul>
            </div> */}
            <div>
              <h5 className="text-sm font-bold mb-2 md:mb-5">Contact</h5>
              <ul className="space-y-3">
                {footerLinks.contact.map((link) => (
                  <li className="text-sm opacity-[0.5]">{link.title}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="py-10 border-opacity-[0.2]">
          <p className="text-[16px] opacity-[0.70]">
            Lorem ipsum dolor sit amet consectetur. Velit turpis sit diam enim
            lectus. Cum a adipiscing vitae scelerisque.
          </p>
        </div> */}
        <div className="mt-10 border-t-[0.5px] py-5 border-[#fff] ">
          <p className="text-lg font-bold">© 2024 Anamo, All rights reserved</p>
        </div>
      </div>
    </div>
  );
}
