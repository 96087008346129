import React from "react";

export default function Titlelink({ text1, text2 }) {
  return (
    <div>
      <div className="mb-8 flex">
        <div className="border-[0.6px] border-[#202020] rounded-[3px] px-[10px] py-[5px] text-white text-[12px] font-neue font-medium">
          <span className="opacity-50">{text1} </span>

          <a href="#" className="text-white">
            {text2}
          </a>
        </div>
      </div>
    </div>
  );
}
