import React, { useState } from "react";
import dwelltime from "../../assets/images/services1.jpeg";
import realtime from "../../assets/images/services2.jpeg";
import layertech from "../../assets/images/services3.jpeg";
import remediation from "../../assets/images/services4.jpeg";
import Titlelink from "../others/Titlelink";

export default function ColumnComponent() {
  const [activeTab, setActiveTab] = useState(1);
  const toggleTab = (index) => {
    setActiveTab(index);
  };
  const services = [
    {
      id: 1,
      name: "Anamo Auto Open",
      desc: "Imagine twice the Pen-Test for ½ the cost. Our automated Pen-Testing service harnesses the power of the assembly line, is the 1stto record 100% of all activity from the entire Teams collaboration and where Clients can eaves-drop with 100% access to all processes during the engagement. If transparency in your testing is what your seeking while saving your budget to acquire Anamo CDM, we get it!",
      image: dwelltime,
    },
    {
      id: 2,
      name: "Anamo Assessment",
      desc: "Imagine twice the Pen-Test for ½ the cost. Our automated Pen-Testing service harnesses the power of the assembly line, is the 1stto record 100% of all activity from the entire Teams collaboration and where Clients can eaves-drop with 100% access to all processes during the engagement. If transparency in your testing is what your seeking while saving your budget to acquire Anamo CDM, we get it!",
      image: realtime,
    },
    {
      id: 3,
      name: "Anamo Managed Services",
      desc: "Imagine twice the Pen-Test for ½ the cost. Our automated Pen-Testing service harnesses the power of the assembly line, is the 1stto record 100% of all activity from the entire Teams collaboration and where Clients can eaves-drop with 100% access to all processes during the engagement. If transparency in your testing is what your seeking while saving your budget to acquire Anamo CDM, we get it!",
      image: layertech,
    },
    {
      id: 4,
      name: "Anamo Global",
      desc: `Imagine twice the Pen-Test for ½ the cost. Our automated Pen-Testing service harnesses the power of the assembly line, is the 1stto record 100% of all activity from the entire Teams collaboration and where Clients can eaves-drop with 100% access to all processes during the engagement. If transparency in your testing is what your seeking while saving your budget to acquire Anamo CDM, we get it!`,
      image: remediation,
    },
  ];
  return (
    <div className="mb-20">
      <div className="grid grid-cols-2 gap-28">
        <div>
          <div className="text-white">
            
            <h1 className="text-5xl font-medium  opacity-[0.9]">Our <span className="gradient-text">Services</span></h1>
            <p className="mt-6 text-[16px] opacity-[0.4] mb-9">
              The Anamo CDM collector is robust and captures the following data:
            </p>
          </div>
          <div className="flex flex-col flex-wrap gap-6 justify-center text-[22px]  font-medium  text-white">
            {services.map((title) => (
              <button
                key={title.id}
                className={
                  activeTab === title.id
                    ? "tabs active-tabs text-[#c60000] flex items-center gap-[12px]"
                    : "tabs opacity-50 flex items-center gap-[12px]"
                }
                onClick={() => toggleTab(title.id)}
              >
                {title.name}
              </button>
            ))}
          </div>
        </div>
        <div>
          <div className="bg-[#111] rounded-[18px]" style={{ padding: "34px" }}>
            {services.map((service) => (
              <div
                key={service.id}
                className={
                  activeTab === service.id
                    ? "content active-content block"
                    : "content hidden"
                }
              >
                <div className="flex max-w-xl flex-col items-start justify-between gap-6">
                  <div className="w-full">
                    <img
                      src={service.image}
                      alt="solutions"
                      className="rounded-[20px] h-[220px] w-full object-cover"
                    />
                  </div>
                  <div>
                    <p className="text-[18px] text-white opacity-[0.9]">
                      {service.desc}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
