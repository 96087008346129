import React from "react";
import logo from "../../assets/images/anamologo.png";

export default function Header() {
  return (
    <div>
      {/* <div className="py-[5px] bg-red text-center">
        <p className="text-sm font-neue text-white font-medium">
          Anamo raises $1.2million Pre-seed investment. Read more on Techcrunch.
        </p>
      </div> */}
      <section className="bg-black top-0 md:px-20 py-8 px-10 z-50 text-white font-neue">
        <div className="w-full sm:px-8 md:px-12 lg:px-16 max-w-screen-lg lg:max-w-screen-xl mx-auto relative">
          <div className="flex items-center gap-4 sm:gap-3 justify-between">
            <div className="flex items-center">
              <div>
                <a href="/">
                  <img
                    src={logo}
                    alt="anamologo"
                    className="sm:h-[20px] h-[15px] object-contain"
                  />
                </a>
              </div>
              <div>
                {/* <ul className="flex gap-[36px] text-sm">
                  <li>Our Solutions</li>
                  <li>
                    <a href="/about"> About Us</a>
                  </li>
                  <li>
                    {" "}
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li>
                    <a href="/faq">FAQS</a>
                  </li>
                  <li>
                    <a href="/faq">Blog</a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="nav-buttons flex gap-4">
              {/* <button className="text-[13px] font-medium bg-transparent border-[#5A5A5A] rounded-[4px] border-[0.5px] px-12 py-3 ">
                Contact Us
              </button> */}
              <a
                href="https://app.anamo.io/login/"
                className="text-[13px] font-medium bg-transparent  bg-red rounded-[4px] px-8 py-2 md:px-12 md:py-3 "
              >
                Login
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
