import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { FaRegCirclePlay } from "react-icons/fa6";
import Titlelink from "../components/others/Titlelink";
import Partners from "../components/home/Partners";
import ColumnComponent from "../components/services/ColumnComponent";

export default function Service() {
  return (
    <div className="bg-black font-neue services text-white">
      <section className="relative max-w-[1034px] mx-auto pt-20 sm:pt-24 lg:pt-28 services-hero flex flex-col justify-center items-center mb-20">
        {/* <Titlelink
          text1={"Did you see our new article? "}
          text2={"Check it out @ Medium"}
        /> */}
        <h5 className="text-7xl leading-[80px] text-center font-medium opacity-[0.88] capitalize mb-6">
          Effortlessly <span> manage</span> your Server with our CDM software
          platform
        </h5>
        <p className="opacity-[0.5] max-w-xl mx-auto text-center mb-6">
          ANAMO is the 1st commercially available CDM software platform, built
          upon a common programming language, which address all major components
          of the DHS CDM Cybersecurity program, that combine these specific and
          critical areas of risk management.
        </p>
        {/* <div className="flex gap-3 justify-center ">
          <button className="flex item-center gap-4 btn-red text-sm bg-transparent text-red border-[0.3px] border-red rounded-[1px] py-[11px] px-6">
            Get Started
            <span>
              <BsArrowRight color="#FE0000" size="20" />
            </span>
          </button>
          <button className="text-sm flex item-center gap-3 btn-green bg-[#0C0C0C] text-green rounded py-[11px] px-6">
            Watch Demo{" "}
            <span>
              <FaRegCirclePlay color="#048C78" size="24" />
            </span>
          </button>
        </div> */}
      </section>
      <Partners />
      <div className="mt-28">
        <ColumnComponent />
      </div>

      {/* <section className="mt-20">
        <div className="grid grid-cols-3 mb-20">
          <div>
            <h1 className="text-5xl font-medium  opacity-[0.9]">
              How It <span className="gradient-text">Works</span>
            </h1>
            <p className="mt-6 text-[16px] opacity-[0.4] mb-9">
              The Anamo CDM collector is robust captures the following data
              without Root Access:
            </p>
          </div>
        </div>
        <div className="mb-20">
          <div className="flex justify-center relative">
            <svg
              width="850"
              height="287"
              viewBox="0 0 850 287"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 53.5C19 53.5 39.7719 203.239 109.5 252.5C239.11 344.065 270 42.5003 444.5 22.5C619 2.4996 831 206.5 831 206.5"
                stroke="white"
                stroke-opacity="0.5"
                stroke-dasharray="15 15"
              />
              <circle
                cx="19"
                cy="52"
                r="19"
                fill="#FF5E5E"
                fill-opacity="0.1"
              />
              <circle
                cx="18.75"
                cy="52"
                r="14"
                fill="#FF5E5E"
                fill-opacity="0.2"
              />
              <circle cx="18.75" cy="51.75" r="10.25" fill="#C60000" />
              <circle
                cx="154"
                cy="268"
                r="19"
                fill="#FF5E5E"
                fill-opacity="0.1"
              />
              <circle
                cx="153.75"
                cy="268"
                r="14"
                fill="#FF5E5E"
                fill-opacity="0.2"
              />
              <circle cx="153.75" cy="267.75" r="10.25" fill="#C60000" />
              <circle
                cx="465"
                cy="19"
                r="19"
                fill="#FF5E5E"
                fill-opacity="0.1"
              />
              <circle
                cx="464.75"
                cy="19"
                r="14"
                fill="#FF5E5E"
                fill-opacity="0.2"
              />
              <circle cx="464.75" cy="18.75" r="10.25" fill="#C60000" />
              <circle
                cx="831"
                cy="204"
                r="19"
                fill="#FF5E5E"
                fill-opacity="0.1"
              />
              <circle
                cx="830.75"
                cy="204"
                r="14"
                fill="#FF5E5E"
                fill-opacity="0.2"
              />
              <circle cx="830.75" cy="203.75" r="10.25" fill="#C60000" />
              <path
                d="M40.0211 157.914L40.7641 156.079L46.7843 158.497L49.2353 152.49L51.0705 153.233L47.8862 161.099L40.0211 157.914ZM42.5955 163.991L43.3385 162.156L49.3587 164.575L51.8098 158.568L53.645 159.311L50.4607 167.176L42.5955 163.991Z"
                fill="#EEEEEE"
              />
              <g clip-path="url(#clip0_1_3)">
                <path
                  d="M294.145 140.087L292.177 140.304L291.448 133.857L284.997 134.551L284.78 132.583L293.214 131.652L294.145 140.087ZM298.272 134.936L296.304 135.153L295.575 128.707L289.124 129.4L288.907 127.432L297.341 126.502L298.272 134.936Z"
                  fill="#EEEEEE"
                />
              </g>
              <path
                d="M645.21 80.0786L644.453 78.2491L650.441 75.7523L647.977 69.7507L649.806 68.9938L653.051 76.8344L645.21 80.0786ZM651.307 82.6066L650.55 80.7771L656.538 78.2804L654.074 72.2788L655.903 71.5218L659.147 79.3625L651.307 82.6066Z"
                fill="#EEEEEE"
              />
              <defs>
                <clipPath id="clip0_1_3">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(276.096 133.825) rotate(-51.2948)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div className="grid grid-cols-4">
              <div className="flex items-start gap-4 absolute top-[-30px] left-0 ">
                <div>
                  {" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 2V4H5L4.999 14H18.999L19 4H16V2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H8ZM18.999 16H4.999L5 20H19L18.999 16ZM17 17V19H15V17H17ZM12 2L16 6H13V11H11V6H8L12 2Z"
                      fill="#F3F3F3"
                    />
                  </svg>
                </div>
                <div>
                  <h5 className="text-[24px] font-medium tracking-[-0.48px]">
                    Install Ruby Gem
                  </h5>
                  <p className="text-xs opacity-[0.4]">
                    10 seconds to up and running.
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-4 absolute bottom-[-80px] left-[12%]">
                <div>
                  {" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 2V4H5L4.999 14H18.999L19 4H16V2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H8ZM18.999 16H4.999L5 20H19L18.999 16ZM17 17V19H15V17H17ZM12 2L16 6H13V11H11V6H8L12 2Z"
                      fill="#F3F3F3"
                    />
                  </svg>
                </div>
                <div>
                  <h5 className="text-[24px] font-medium tracking-[-0.48px]">
                    Agent Transmits
                  </h5>
                  <p className="text-xs opacity-[0.4] w-[258px]">
                    Open ports, mere software package names and versions, and
                    file/directory permissions are securely transmitted. Alone,
                    not very useful data.
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-4 absolute left-[40%] top-[-70px]">
                <div>
                  {" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 2V4H5L4.999 14H18.999L19 4H16V2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H8ZM18.999 16H4.999L5 20H19L18.999 16ZM17 17V19H15V17H17ZM12 2L16 6H13V11H11V6H8L12 2Z"
                      fill="#F3F3F3"
                    />
                  </svg>
                </div>
                <div>
                  <h5 className="text-[24px] font-medium tracking-[-0.48px]">
                    View on Anamo.io
                  </h5>
                  <p className="text-xs opacity-[0.4] w-[258px]">
                    Carefully curated data and alerts are meant to get right
                    information to you without excessive “data noise”
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-4 absolute right-[-30px] bottom-[-30px]">
                <div>
                  {" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 2V4H5L4.999 14H18.999L19 4H16V2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H8ZM18.999 16H4.999L5 20H19L18.999 16ZM17 17V19H15V17H17ZM12 2L16 6H13V11H11V6H8L12 2Z"
                      fill="#F3F3F3"
                    />
                  </svg>
                </div>
                <div>
                  <h5 className="text-[24px] font-medium tracking-[-0.48px] w-[272px] leading-normal">
                    Securely Processed in the Anamo Cloud
                  </h5>
                  <p className="text-xs opacity-[0.4] w-[258px]">
                    All of the processing, risk, calculation, technical analysis
                    happens in our secure cloud.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="relative max-w-[1034px] mx-auto pt-16 sm:pt-24 lg:pt-12 flex flex-col justify-center items-center">
        {/* <Titlelink
          text1={"CDM platform for businesses. "}
          text2={"Evaluate asset CVE vulnerability by OS and criticality."}
        /> */}
        <h5 className="text-5xl max-w-3xl mx-auto text-center  opacity-[0.9] capitalize mb-6">
          Streamline your Server management with ease - powered by our CDM
          software platform.
        </h5>
        <p className="opacity-[0.5] max-w-xl mx-auto text-center mb-6">
          Transform your data management today with our CDM software platform
        </p>
        <div className="flex gap-3 justify-center ">
          <a href="" className="flex item-center gap-4 btn-red text-sm bg-transparent text-red border-[0.3px] border-red rounded-[1px] py-[11px] px-6">
            Get Started
            <span>
              <BsArrowRight color="#FE0000" size="20" />
            </span>
          </a>
          {/* <button className="text-[13px] font-medium bg-transparent  bg-red rounded-[4px]  px-12 py-3 ">
            Login
          </button> */}
        </div>
      </section>
    </div>
  );
}
