import React from "react";

import linuxlogo from "../../assets/images/partners/linux.png";
import unixlogo from "../../assets/images/partners/unix.png";
import solarislogo from "../../assets/images/partners/solaris.png";
import aixlogo from "../../assets/images/partners/aix.png";
import googlecloudlogo from "../../assets/images/partners/googlecloud.png";
import azurelogo from "../../assets/images/partners/azure.png";
import windowslogo from "../../assets/images/partners/windows.png";
import awslogo from "../../assets/images/partners/aws.png";
import usprotech from "../../assets/images/partners/usprotech.png";

// import mapbg from "../../assets/images/vectors/map.png";
export default function Partners() {
  const partnerlogos = [
    // { name: "USProTech", image: usprotech },
    { name: "Linux", image: linuxlogo },
    { name: "Unix & OpenServer", image: unixlogo },
    { name: "Solaris", image: solarislogo },
    { name: "AIX", image: aixlogo },
    { name: "Googlecloud", image: googlecloudlogo },
    { name: "Azure", image: azurelogo },
    { name: "Windows Server", image: windowslogo },
    { name: "Amazon Web Services (AWS)", image: awslogo },
  ];
  return (
    <div>
      <section className="mx-auto text-white relative mt-14">
        <div>
          <h1
            className="md:text-4xl text-[32px] text-center
         leading-[56px] font-medium tracking-[-0.88px] sm:text-[44px]"
          >
            Our <span className="gradient-text"> Partners</span>{" "}
          </h1>
        </div>
        <div class="flex items-center gap-2 sm:gap-6 justify-center mt-2 md:mt-12">
          <div>
            <img src={usprotech} alt="usprotech" className="z-10 w-[145px] " />
          </div>
          {partnerlogos.map((logo) => (
            <div>
              <img src={logo.image} alt={logo.name} className="z-10 " />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
