import React from "react";
import Accordion from "./Accordion";

const AccordionContent = [
  {
    index: "1",
    question: "How Much Does Anamo Cost?  /  How do I get a Quote for Anamo? ",
    answer: (
      <>
        Contact Sales:{" "}
        <a href="mailto: Sales@anamo.io" style={{ color: "#467886" }}>
          Sales@Anamo.io
        </a>{" "}
        / 949.629.3900
      </>
    ),
  },
  {
    question: "Why would my company want to consider using Anamo CDM?",
    answer: (
      <>
        Offering twice the capability at half the price seems to make sense to
        many
      </>
    ),
  },
  {
    question: "Does Anamo offer any Free Access to its CDM Platform?",
    answer:
      "“Yes” Anamo offers limited Free-Trials and NFR Licensing to qualified Distributors & Partners.",
  },
  {
    question: "Does Anamo offer any special purchasing discount? ",
    answer:
      "“Yes” Anamo does provide special discounts to Social, Military, Educational, Religious, Fraternal and Political organizations.",
  },
];

const getAccordion = AccordionContent.map((item) => (
  <Accordion
    key={item.question}
    question={item.question}
    answer={item.answer}
    content={item.C}
  />
));
function AccordionCard(props) {
  return (
    <div className="grid md:grid-cols-2 gap-x-14 gap-y-5">{getAccordion}</div>
  );
}

export default AccordionCard;
