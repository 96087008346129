import React from "react";
import Titlelink from "../components/others/Titlelink";
import teamImage from "../assets/images/team.jpeg";
import { BsArrowRight } from "react-icons/bs";
import { PiSealCheckFill } from "react-icons/pi";

export default function Pricing() {
  return (
    <div className="bg-black text-white pricing-page font-neue">
      <section className="about-hero flex flex-col justify-center text-center items-center">
        <Titlelink
          text1="Did you see our new article?"
          text2="Check it out @ Medium"
        />
        <div className="max-w-5xl mx-auto">
          <h5 className="text-6xl font-medium opacity-[0.88] capitalize mb-6">
            Ready To Get <span className="gradient-text">Started?</span>
          </h5>
          <p className="opacity-[0.5] max-w-xl mx-auto">
            Lorem ipsum dolor sit amet consectetur. Feugiat fermentum purus et
            ipsum amet egestas vel tristique. Mattis a sodales dictum orci
            pretium. Quis aliquet gravida ac luctus. Vitae tempus gravida justo
            morbi ut mi turpis sed ut. Sit accumsan odio integer pulvinar a
            aliquet ipsum egestas.
          </p>
        </div>
      </section>
      <section>
        <section>
          <div className="container mx-auto p-6 overflow-x-auto font-neue">
            <table className="w-full table-fixed border-collapse border border-[#858BA0]">
              <thead>
                <tr>
                  <th className="border border-[#858BA0]">
                    <div className="flex gap-3 items-center">
                      <h2 className="text-xl font-bold"> Compare plans</h2>
                      <button className="py-2 px-4 border-[#858bA0] border-[0.45px] rounded-2xl text-sm opacity-[0.80]">
                        {" "}
                        40% Off
                      </button>
                    </div>
                    <p className="text-xs text-[#858BA0] font-medium text-left flex mt-3">
                      Choose your workspace plan according to your
                      organisational plan
                    </p>
                  </th>
                  <th scope="col" className="border border-[#858BA0]">
                    <p className="mb-3">
                      <span className="text-2xl font-bold sm:text-4xl dark:text-gray-50">
                        Free
                      </span>{" "}
                      <span className="font-medium text-[13px] text-[#858BA0]">
                        /Lifetime
                      </span>
                    </p>
                    <button className="text-[13px] font-bold bg-transparent  bg-red rounded-[4px]  px-12 py-3 ">
                      Choose This Plan
                    </button>
                  </th>
                  <th scope="col" className="border border-[#858BA0]">
                    <p className="mb-3">
                      <span className="text-2xl font-bold sm:text-3xl text-white">
                        25
                      </span>{" "}
                      <span className="font-medium text-[13px] text-[#858BA0]">
                        /Month
                      </span>
                    </p>
                    <button className="text-[13px] font-bold bg-transparent  bg-red rounded-[4px]  px-12 py-3 ">
                      Choose This Plan
                    </button>
                  </th>
                  <th scope="col" className="border border-[#858BA0]">
                    <p className="mb-3">
                      <span className="text-2xl font-bold sm:text-4xl dark:text-gray-50">
                        49
                      </span>{" "}
                      <span className="font-medium text-[13px] text-[#858BA0]">
                        /Month
                      </span>
                    </p>
                    <button className="text-[13px] font-bold bg-transparent  bg-red rounded-[4px]  px-12 py-3 ">
                      Choose This Plan
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody className="space-y-6 text-center divide-y dark:divide-gray-700">
                <tr className="border border-[#858BA0]">
                  <th scope="row" className="text-left border border-[#858BA0]">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      Number of Users
                    </h3>
                  </th>
                  <td>
                    <span className="block text-sm">20 Pages</span>
                  </td>
                  <td>
                    <span className="block text-sm">600 pages</span>

                    <span className="block text-sm">
                      Pages Add-ons on Demand
                    </span>
                  </td>
                  <td>
                    <span className="block text-sm">Unlimited</span>
                    <span className="block text-sm">
                      Pages Add-ons on Demand
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-left border border-[#858BA0]">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      Users Per Page
                    </h3>
                  </th>
                  <td>
                    <span className="block text-sm">50</span>
                  </td>
                  <td>
                    <span className="block text-sm">Unlimited</span>
                  </td>
                  <td>
                    <span className="block text-sm">Unlimited</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-left border border-[#858BA0]">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      Includes essential features to get started
                    </h3>
                  </th>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-left border border-[#858BA0]">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      More advanced features for increased productivity
                    </h3>
                  </th>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-left">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      Designing & Development
                    </h3>
                  </th>
                  <td></td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-left">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      Customizable options to meet your specific needs
                    </h3>
                  </th>
                  <td></td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-left">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      Secure data storage
                    </h3>
                  </th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-left">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      Email Support
                    </h3>
                  </th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-left">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      24/7 customer support
                    </h3>
                  </th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-left">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      Analytics and reporting
                    </h3>
                  </th>
                  <td></td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-left">
                    <h3 className="py-3 text-[16px] opacity-[0.8] font-medium">
                      Account Management
                    </h3>
                  </th>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                  <td>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="mx-auto"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.6795 2.02489C9.83401 1.31128 8.59721 1.31129 7.75176 2.02489L6.98179 2.67477C6.62245 2.97807 6.17777 3.16228 5.7092 3.2019L4.70521 3.28681C3.60279 3.38004 2.72824 4.25459 2.63501 5.35701L2.55011 6.361C2.51048 6.82956 2.32628 7.27424 2.02298 7.6336L1.37309 8.40355C0.65949 9.24901 0.659491 10.4858 1.37309 11.3313L2.02298 12.1012C2.32628 12.4606 2.51048 12.9053 2.55011 13.3738L2.63501 14.3778C2.72824 15.4803 3.60279 16.3548 4.70521 16.448L5.7092 16.5329C6.17777 16.5726 6.62245 16.7568 6.9818 17.0601L7.75176 17.71C8.59721 18.4235 9.83401 18.4235 10.6795 17.71L11.4494 17.0601C11.8088 16.7568 12.2535 16.5726 12.722 16.5329L13.7261 16.448C14.8285 16.3548 15.703 15.4803 15.7962 14.3778L15.8811 13.3738C15.9208 12.9053 16.105 12.4606 16.4083 12.1012L17.0582 11.3313C17.7717 10.4858 17.7717 9.24901 17.0582 8.40355L16.4083 7.63358C16.105 7.27424 15.9208 6.82956 15.8811 6.361L15.7962 5.35701C15.703 4.25459 14.8285 3.38004 13.7261 3.28681L12.722 3.2019C12.2535 3.16228 11.8088 2.97807 11.4494 2.67477L10.6795 2.02489ZM13.3425 8.32009C13.7414 7.92126 13.7414 7.27461 13.3425 6.87578C12.9438 6.47694 12.2971 6.47694 11.8982 6.87578L8.08134 10.6927L6.53398 9.1453C6.13515 8.74646 5.4885 8.74646 5.08967 9.1453C4.69082 9.54414 4.69082 10.1908 5.08967 10.5896L7.35919 12.8591C7.75802 13.258 8.40467 13.258 8.8035 12.8591L13.3425 8.32009Z"
                        fill="white"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </section>
      <section className="about-hero flex flex-col justify-center text-center items-center">
        <Titlelink
          text1="Did you see our new article?"
          text2="Check it out @ Medium"
        />
        <div className="max-w-5xl mx-auto">
          <h5 className="text-[44px] font-medium opacity-[0.88] capitalize mb-6 max-w-[714px] tracking-[-0.88]">
            Join over 100k users daily and optimise your servers and security
          </h5>
          <p className="opacity-[0.5] max-w-xl mx-auto">
            Transform your data management today with our CDM software platform
          </p>
          <div className="flex gap-3 justify-center mt-9">
            <button className="flex item-center gap-4 btn-red text-sm bg-transparent text-red border-[0.3px] border-red rounded-[1px] py-[11px] px-6">
              Get Started
              <span>
                <BsArrowRight color="#FE0000" size="20" />
              </span>
            </button>
            <button className="text-[13px] font-medium bg-transparent  bg-red rounded-[4px]  px-12 py-3 ">
              Login
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
