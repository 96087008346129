import Homepage from "./pages/Homepage";
import "./assets/scss/app.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/global/Header";
import Footer from "./components/global/Footer";
import About from "./pages/About";
import Service from "./pages/Service";
import Pricing from "./pages/Pricing";
import FAQ from "./pages/FAQ";
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Service />} />
          <Route path="/pricing" element={<Pricing/>}/>
          <Route path="/faq" element={<FAQ/>}/>
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
