import { useState } from "react";
import { AiOutlinePlus, AiFillCloseCircle } from "react-icons/ai";
import { RiAddBoxFill } from "react-icons/ri";

function Accordion({ question, answer, content, index }) {
  const [expand, setExpand] = useState(false);
  function handleExpand() {
    setExpand(!expand);
  }

  return (
    
    <div>
      <section
        className="bg-[#0c0c0c] rounded-[4px] px-[20px] py-[15px]"
        onClick={handleExpand}
        onKeyDown={handleExpand}
        tabIndex={0}
        role="button"
      >
        <div className={expand ? "mb-5 top-div-active top-div" : ""}>
          <div className="flex justify-between items-center">
            <p className="text-[16px] font-neue font-medium">{question}</p>
            <p>
              {expand ? (
                <RiAddBoxFill
                  style={{ fontSize: "30px", color: "#c60000", transform:"rotate(-47.905deg)" }}
                />
              ) : (
                <RiAddBoxFill
                style={{ fontSize: "30px", color: "#c60000"}}
              />
              )}
            </p>{" "}
          </div>{" "}
        </div>{" "}
        <div
          style={{
            display: expand ? "block" : "none",
          }}
          className="bottom-div"
        >
          {" "}
          <p className="text-[16px] font-neue"> {answer}</p>{" "}
        </div>{" "}
      </section>
    </div>
  );
}

export default Accordion;