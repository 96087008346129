import React, { useState } from "react";
// import dwelltime from "../../assets/images/services1.jpeg";
// import realtime from "../../assets/images/services2.jpeg";
// import layertech from "../../assets/images/services3.jpeg";
// import remediation from "../../assets/images/services4.jpeg";
import forensicpermission from "../../assets/images/services/forensicspermission.png";
import forensicsoftware from "../../assets/images/services/forensicsoftware.png";
import realtime from "../../assets/images/services/realtime-dashboard.png";
import remediation from "../../assets/images/services/remediationreporting.png";
import insiderthreat from "../../assets/images/services/insiderthreat.png";
import vulnerability from "../../assets/images/services/vulnerability.png";
import zerotrust from "../../assets/images/services/zerotrust-administration.png";
import zerotrustgrc from "../../assets/images/services/zerotrust-grc.png";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
export default function TabComponent() {
  const [activeTab, setActiveTab] = useState(1);
  const toggleTab = (index) => {
    setActiveTab(index);
  };
  const solutions = [
    {
      id: 1,
      name: "Forensics & Permissions",
      desc: (
        <>
          The one constant in Cybersecurity is the need for deep forensic data
          collection. Sadly, most cybersecurity products gravely lack an ability
          to identify, collect, or understand the significance of such data
          collection. An example of “struggling” can be found with those who
          have enabled logging within their systems but ignore the vast
          quantities of alert notifications, likening them to white noise. Anamo
          solves this dilemma by allowing for customization as several levels…
          but more importantly, Anamo understand exactly what Forensic
          Information is essential to enable the InfoSec Team to pinpoint an
          exact event, transaction, date, time, by device, etc.
          <br />
          <br />
          One example of essential forensic data collection that Anamo excels in
          would be the attributes of Users and Groups Permissions.
          <br />
          First, Anamo automatically gathers all associations of users and
          groups permissions regardless of what Operation System (OS) that they
          use (Windows or Linux). Anamo then automatically present both OS’s
          permission in their native format (numeric or symbolic) and allows the
          InfoSec administrator several options to interrogate such as hosts,
          path, etc.
          <br />
          <br />
          Anamo goes further than that and tracks even the most subtle
          permission changes and offers the Admin their choice of how quickly
          such an event would generate an alarm. In short, Anamo autonomously
          gathers the data and delivers deep forensic tracking to the Admins and
          displays both Data and Alerts logically for remediation and
          investigative purposes.
        </>
      ),
      image: forensicpermission,
    },
    {
      id: 2,
      name: "Forensics & Software",
      desc: `Anamo Software Forensics enable InfoSec teams to quickly identify any protected
system (by OS), by Name, by Type, by Version (Old &amp; New), Creation Date, by Action,
and other historical data based upon any selected timeframe over a variety of other
different criteria.`,
      image: forensicsoftware,
    },
    {
      id: 3,
      name: "Real Time Dashboards",
      desc: (
        <>
          <span style={{ fontSize: "18px" }}>
            It almost sounds like Anamo solved the puzzle that has haunted the
            IT InfoSec Team for ages.
          </span>
          <br />
          <br />
          You know the question, the one where people ask, “And then… what do we
          do with those Logs?” In reality, Logs serve an important purpose but
          unfortunately, they do so after the fact. Anamo understands what the
          purpose of Logs are, but rather than collecting them (which is always
          a good idea), Anamo interprets the meaning, actions, and behavior of
          the User (or Group) to essentially monitor, notify, and alert Admins
          to the actions of a Technical-Adversary (Authorized or not) who might
          be categorized as a suspicious or bad actor. Today, that problem is
          solved through the ingenious and tastefully presented user interface
          that does not require a computer science degree to immediately gain
          the gist and detail that has been woven into the Anamo fabric.
        </>
      ),
      image: realtime,
    },
    {
      id: 4,
      name: "Immediate and Comprehensive Remediation Reporting",
      desc: (
        <>
          <span style={{ fontSize: "18px" }}>
            Anamo is continuous diagnostics and mitigation, it delivers
            “always-on” vulnerability assessment scanning and no competitor can
            scan as often or as deeply as Anamo. There are many types of
            “external scanners”, but they are not behind the firewall and
            limited to the type of data that the scanner can collect, this
            limitation leaves the customer with (potentially) knowing the
            problem but without the essential ability to receive remediation
            validation in real-time. VM (Virtual-Machine) based Nodes are also
            able to scan for CVE’s and are able to evaluate a client
            vulnerability risk posture more rapidly than external scanners;
            however, they all remain limited in their ability to gather critical
            and essential data for decision-makers in real-time. VM’s do not
            reside within the Host and therefore cannot gather the same
            deep-seeded OS Level information that the Anamo Agent continuously
            gathers 24/7/365.
          </span>
          <br />
          <br />
          Easily identifiable differentiators: Scanners cannot see beyond a
          named software and have zero insight into the packages that make up
          the software. VM’s – posing as internal scanning node are not
          agent-based, an extremely limited purview into a devices operating
          system, but they can reach limited information from more devices
          without an Agent. The Anamo Agent sits directly upon the protected
          system and is intimately knowledgeable with nearly every single
          attribute and possible attack vector far beyond software and all the
          way down to a user, their permissions, and subsequent behavioral
          modifications.
          <br />
          <br />
          Results: Remediation efforts in Anamo can be seen and verified by the
          InfoSec Admin Team in near Real-Time, no more waiting a month for or
          the next scan to evaluate an outdated Report.
          <br />
          <br />
          Example: Anamo automatically separates software vulnerability by OS,
          Kernel, Package, System, NVD allocated Score, Severity, Risk,
          Exploitability, and much, much more.
        </>
      ),
      image: remediation,
    },
    {
      id: 5,
      name: "Insider Threat",
      desc: (
        <>
          <span style={{ fontSize: "18px" }}>
            Anamo is the answer to identifying a Technical Adversary and
            delivers the critical data needed to effectively eliminate the
            Dwell-Time of an attacker.
          </span>
          <br />
          <br />
          Anamo CDM provides an AI and ML-driven SaaS that is disrupting the
          classic and stagnated Cybersecurity-Silos of old such as Endpoint
          Protection Platforms <b>(EPP)</b>, Endpoint Detection and Remediation{" "}
          <b>(EDR)</b>, Attack Surface Management <b>(ASM)</b>, Software
          Vulnerability Management <b>(SVM)</b>, and Security Event Incident
          Management
          <b> (SIEM)</b>, just to name a few. While Anamo isn’t a Silver-Bullet
          for every Cybersecurity challenge, one look through any of the Gartner
          Reports <em>(see embedded links)</em> will show that Anamo merges many
          of the best attributes of these aforementioned security functions into
          a single platform and delivers a comprehensive, user-friendly, and
          financially attractive entry-point and value proposition that legacy
          platforms simply cannot compete with.
          <br />
          <br />
          Contact{" "}
          <span style={{ color: "#467886" }}>
            <em>Sales</em>
          </span>{" "}
          at Anamo to learn just how affordable CDM can be for you!
        </>
      ),
      image: insiderthreat,
    },
    {
      id: 6,
      name: "Software Vulnerability",
      desc: (
        <>
          <span style={{ fontSize: "18px" }}>
            Anamo knew that no other software product could deliver risk-rated
            software CVE analytics with remediation recommendations on both
            Windows and Linux Operating Systems, and at the same time separated
            and categorized those vulnerabilities by Host , Version, Build,
            Package, OS, Kernel, Impact, and Score all on the same page. So
            Anamo built that. But that wasn’t going to be enough for Anamo
            customers, clients wanted deep software and historical forensics
            with data analytics, along with transaction history, and they
            demanded that the information be constantly updated and delivered to
            an active dashboard in near real-time. So Anamo did that too!
          </span>
          <br /> <br />
          Subsequently, Anamo built the entire SaaS platform on a single set of
          robust, and scalable programming languages that is offered today in
          three flavors:
          <ol>
            <li>
              1. <b>Fundamentals</b> <br /> Built for LAN Administration for
              Workstations, PC’s, etc.
            </li>
            <li>
              2. <b>Professional</b> <br /> Built for Wan Administrators with
              complex Multi-OS Server farms.
            </li>
            <li>
              3. <b>Enterprise</b> <br /> Built for both LAN and WAN
              Administration{" "}
            </li>
          </ol>
          <br />
          Anamo even delivers 100% Service Level Administration with its
          professional and technical engineering team who offer 24/7 SOC
          Services!
        </>
      ),
      image: vulnerability,
    },
    {
      id: 7,
      name: "Zero Trust Administration and Anamo",
      desc: (
        <>
          <span style={{ fontSize: "18px" }}>
            Seeking the ability to effectively deploy a ZTA capability?{" "}
          </span>
          <br />
          <br />
          <span style={{ fontSize: "18px" }}>
            {" "}
            Before one can anticipate achieving ZTA with any platform or
            service, entities will first require the initial ability to gather
            and understand correlated data. These trust indicators should
            include, at a minimum, Cybersecurity risk data related to identity
            attributes concerning Users, Groups, Port, and Permissions, all in
            an effort to ensure that they have correct and robust data forensics
            gathering capability for validation and verification purposes.
          </span>
          <br />
          <br />
          Anamo is essentially your trusted 3rd party validation system to any
          ZTA effort and provides the essential data sets needed to
          operationally validate a working ZTA system.
        </>
      ),
      image: zerotrust,
    },
    {
      id: 8,
      name: "Anamo ZTA and GRC Administration ",
      desc: (
        <>
          <span style={{ fontSize: "18px" }}>
            If you represent a DoD supply chain operator or have a federal
            regulatory compliance requirement such as CMMC, NIST 800, HIPAA, or
            SOX, you’ll feel right at home and well-accommodated by enhancing
            the ZTA program with Anamo CDM.
          </span>
          <br />
          <br />
          Simply stated, ZTA is a multi-faceted aspect of Cybersecurity which
          includes a clear understanding and the ability to track each and every
          User or Group of Users IDs within select computing systems. At a
          highly granular-level, Anamo tracks, documents, and even alerts
          InfoSec team members of any modifications of individuals, groups,
          ports, or permissions including Adds, Changes, Deletes, and more.
          <br />
          <br />
          To accommodate various Federal Regulatory Compliance (GRC)
          requirements, the “GRC” (Governance, Risk, and Compliance) Platform is
          100% encrypted and delivers a highly secured platform to qualified
          clients who deal with CUI, PII, or other sensitive, controlled, or
          regulated information. The GRC APP enables On-Demand assessment
          management, policy, procedure, and compliance management, as well as
          delivering instant Security System Plans (SSP) or Facility Security
          Plans (FSP). Additionally, the GRC manages compliance deadlines,
          POAM’s (Plan of Action & Milestones) and more.
        </>
      ),
      image: zerotrustgrc,
    },
  ];
  return (
    <div className="text-white">
      <div className="tab-buttons z-10 flex flex-wrap lg:flex-nowrap justify-center text-[16px]  font-medium text-center text-gray-500 dark:text-gray-400">
        {solutions.map((title) => (
          <button
            key={title.id}
            className={
              activeTab === title.id
                ? "tabs active-tabs flex items-center z-10 gap-[10px]"
                : "tabs flex items-center z-10 gap-[10px]"
            }
            onClick={() => toggleTab(title.id)}
          >
            <span> {title.icon}</span>
            {title.name}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {solutions.map((solution) => (
          <div
            key={solution.id}
            className={
              activeTab === solution.id
                ? "content  active-content block"
                : "content hidden"
            }
          >
            <div className="grid lg:grid-cols-2 gap-10 xl:gap-1 relative pt-10 md:pt-20 lg:max-w-none max-w-2xl md:max-w-3xl mx-auto custom-zoom">
              <div>
                <p className="text-[16px]">{solution.desc}</p>
              </div>
              <div className="w-full md:pl-10">
                <Zoom>
                  <img
                    src={solution.image}
                    alt="solutions"
                    className="rounded-[20px] w-full object-contain"
                  />
                </Zoom>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
