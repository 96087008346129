import React from "react";
import TabComponent from "../components/others/TabComponent";
import { FaRegCirclePlay } from "react-icons/fa6";
import { BsArrowRight } from "react-icons/bs";
import Partners from "../components/home/Partners";
// import ColumnTabComponent from "../components/home/ColumnTabComponent";
import mapbg from "../assets/images/vectors/map.png";
import AccordionCard from "../components/faq/AccordionCard";
import RadialVector from "../components/others/RadialVector";
// import radial from "../assets/images/RADIAL SHADE.svg";
import anamocdm from "../assets/images/services/anamocdm.png";

export default function Homepage() {
  // const packages = [
  //   {
  //     id: 1,
  //     title: "OS Software NVD Vulnerabilities",
  //     logo: "",
  //     desc: "Lorem ipsum dolor sit amet consectetur. Etiam ut viverra vitae sem mattis semper sit.",
  //   },
  //   {
  //     id: 2,
  //     title: "Windows Software Package Changes",
  //     logo: "",
  //     desc: "Lorem ipsum dolor sit amet consectetur. Etiam ut viverra vitae sem mattis semper sit.",
  //   },
  //   {
  //     id: 3,
  //     title: "Debian Package CVE’s and Changes",
  //     logo: "",
  //     desc: "Lorem ipsum dolor sit amet consectetur. Etiam ut viverra vitae sem mattis semper sit.",
  //   },
  //   {
  //     id: 4,
  //     title: "RPM Package CVE’s and Changes",
  //     logo: "",
  //     desc: "Lorem ipsum dolor sit amet consectetur. Etiam ut viverra vitae sem mattis semper sit.",
  //   },
  //   {
  //     id: 5,
  //     title: "File System History, Paths, Changes & Entries",
  //     logo: "",
  //     desc: "Lorem ipsum dolor sit amet consectetur. Etiam ut viverra vitae sem mattis semper sit.",
  //   },
  //   {
  //     id: 6,
  //     title: "Users and Groups ID’s & Permissions",
  //     logo: "",
  //     desc: "Lorem ipsum dolor sit amet consectetur. Etiam ut viverra vitae sem mattis semper sit.",
  //   },
  //   {
  //     id: 7,
  //     title: "User / Group Membership Changes",
  //     logo: "",
  //     desc: "Lorem ipsum dolor sit amet consectetur. Etiam ut viverra vitae sem mattis semper sit.",
  //   },
  //   {
  //     id: 8,
  //     title: "Group User Membership",
  //     logo: "",
  //     desc: "Lorem ipsum dolor sit amet consectetur. Etiam ut viverra vitae sem mattis semper sit.",
  //   },
  //   {
  //     id: 9,
  //     title: "Ports: Netstat History and Port Changes",
  //     logo: "",
  //     desc: "Lorem ipsum dolor sit amet consectetur. Etiam ut viverra vitae sem mattis semper sit.",
  //   },
  // ];
  return (
    <div className="homepage overflow-hidden">
      <div className="hero-wrapper">
        <div className="hero-content">
          <div className="hero-left">
            {/* <Titlelink
              text1={"Did you see todays latest software vulnerabilities?"}
              text2={"Check out our free report"}
            /> */}
            <div className="hero-left__title">
              <h1>
                Anamo is CDM,
                <br />A Cybersecurity Platform Delivering Continuous,
                Diagnostics, & Mitigation
              </h1>
            </div>
            <div className="hero-left__desc mb-6">
              <p className="opacity-50">
                ANAMO is the 1st commercially available CDM software platform,
                built upon a common programming language, which address all
                major components of the DHS CDM Cybersecurity program. Anamo
                combines these specific and critical areas of risk management to
                simplify attack surface management (ASM).
              </p>
            </div>
            <div className="hero-left__btns">
              <a
                className="flex item-center gap-4 btn-red bg-transparent text-red border-white rounded py-[11px] px-6"
                target="blank"
                href="https://app.anamo.io/login"
              >
                Get Started
                <span>
                  <BsArrowRight color="#FE0000" size="20" />
                </span>
              </a>
              <a
                href="https://youtu.be/BPR_sTWgjPc"
                className="flex item-center gap-3 btn-green bg-transparent text-green rounded py-[11px] px-6"
                target="blank"
              >
                DHS/CDM Video{" "}
                <span>
                  <FaRegCirclePlay color="#048C78" size="24" />
                </span>
              </a>
            </div>
            <div className="hero-left__stat">
              <p>Live Dashboard</p>
              <p>Insider-Threat</p>
              <p>Vulnerability</p>
              <p>Permissions</p>
              <p>Zero Trust</p>
              <p>Forensics</p>
            </div>
          </div>
        </div>
      </div>

      <div className=" text-white w-full max-w-screen-lg lg:max-w-screen-xl mx-auto relative mt-16">
        <div className="text-center mb-8 md:mb-3 pt-6">
          <h1 className="text-4xl max-w-2xl mx-auto md:leading-[56px] font-medium tracking-[-0.88px] md:text-[44px]">
            What makes Anamo Unique?
          </h1>
        </div>
        <p className="opacity-90 mb-5 ">
          <span style={{ fontWeight: "500", fontSize: "18px" }}>
            Anamo CDM, a patent-pending commercial-grade CDM Cybersecurity
            platform, has answered the call of CISA, the DHS, and other requests
            from the DoD Supply-Chain.
          </span>
          <br />
          <br />
          Harnessing the power of advanced ML and Generative AI, Anamo CDM is
          pushing toward the delivery of “Predictive Cybersecurity Threat
          Extrapolation.” How so? Anamo collects essential User, Group, Risk,
          Permission, Vulnerability, Software, History, and Forensic data and
          dynamically delivers that requested information in near Real-Time. Due
          to the platform service delivery, Anamo has captured broad interest
          from US- based enterprise businesses and many members/agencies across
          the US Aerospace and Defense supply chain. Subsequently, Anamo has
          enjoyed receiving invitations to visits at both the White House and
          the Pentagon.
        </p>
        <img
          src={anamocdm}
          alt="anamocdm"
          className="pt-5 w-full object-contain"
        />
      </div>
      <section className="mt-[120px] text-white relative mb-16">
        <div className="absolute left-[-483px] bottom-[-91px]">
          <RadialVector />
        </div>
        <div className="w-full max-w-screen-lg lg:max-w-screen-xl mx-auto">
          <div className="md:grid grid-cols-12  gap-10 relative">
            <div className="col-span-7">
              <div className="bg-[#111] py-6 px-9 rounded-[18px] z-10">
                <h1 className="md:text-[40px]  text-[32px] font-medium  mb-2 ">
                  Anamo CDM - Unparalleled
                </h1>
                <p className="text-[16px] mb-4 ">
                  Anamo, commercial businesses and the US Government desire a
                  functional ability that can present the most important
                  security data to active dashboards. This functionality is
                  ideal and provides a process to elevate resource allocations
                  and define remediation priorities within their respective
                  divisions and to the appropriate staff and authorities. CDM is
                  simply superior Cybersecurity and Anamo CDM is the leading
                  commercial solution.
                </p>

                <p style={{ textDecoration: "underline" }}>
                  {" "}
                  <a
                    href="https://docs.google.com/spreadsheets/d/1MC4aHH76C422gH8TztOZvTY1UOc755Hz/edit?usp=sharing&ouid=102907685997306947529&rtpof=true&sd=true"
                    target="blank"
                  >
                    Who are the over 100 US Federal Agencies that have already
                    deployed CDM as a core capability of their Cybersecurity
                    Defense program?
                  </a>
                </p>
                <br />
                <p>
                  {" "}
                  Anamo is 24/7/365: In near Real-Time and On-Demand, Anamo CDM
                  delivers a cross-section of many of the best Cybersecurity
                  attributes and capabilities of industry verticals including:
                </p>
                <ul className="text-[16px] list-disc  pl-7 mb-8">
                  <li>SIEM – Security Incident Event Management </li>
                  <li>EDR – Endpoint Detection & Response</li>
                  <li>ASM - Attack Surface Management</li>
                  <li>Vul-Scan - CVE Vulnerability Scanning</li>
                  <li>EPP - Endpoint Protection</li>
                  <li>BA - Behavior Awareness</li>
                </ul>
                <p className="text-[16px] ">
                  <a
                    href="https://www.cisa.gov/sites/default/files/publications/2020%2009%2003_CDM%20Program%20Overview_Fact%20Sheet_1.pdf"
                    target="blank"
                    style={{ textDecoration: "underline", fontStyle: "italic" }}
                  >
                    Click here{" "}
                  </a>
                  to get answers to your CDM program questions directly from
                  CISA and the DHS
                </p>
              </div>
            </div>
            <div className="col-span-5 md:mt-0 mt-10">
              <h1 className=" md:text-right   text-[32px] md:text-[40px] font-medium mb-2 md:mb-5 ">
                What is CDM?
              </h1>
              <p className=" md:text-right text-[16px]">
                ​The Continuous Diagnostics and Mitigation (CDM) Program
                provides a dynamic approach to fortifying the cybersecurity
                capabilities of businesses and government agencies and the
                networks and systems that they manage and protect. CDM was
                created by the U.S. Department of Homeland Security and is a
                critical security component of CISA (Cybersecurity and
                Infrastructure Security Agency) of the United States of America.
                <br />
                <br />
                The DHS and CISA created the multi-billion-dollar CDM program in
                an effort to assemble critical Cybersecurity risk information
                and logically display various categories to certain Dashboards
                in as close to real-time as possible.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="solutions w-full max-w-screen-lg lg:max-w-screen-xl mx-auto relative">
        <div className="title">
          Comprehensive Cybersecurity <br className="break" /> Solution suite
        </div>
        <img
          src={mapbg}
          alt="vector"
          className="absolute mix-blend-overlay top-[-103px] right-[-82px]"
        />
        <TabComponent />
      </div>

      {/* <section className="mx-auto text-white py-32 sm:py-48 lg:py-56 relative">
        <div className="absolute left-[-483px] top-[450px] z-10">
          <RadialVector />
        </div>
        <div className="absolute right-[-483px] bottom-[300px] z-10">
          <RadialVector />
        </div>
        <div>
          <div className="flex flex-col items-center  ">
            <Titlelink
              text1={"CDM platform for businesses."}
              text2={"Evaluate asset CVE vulnerability by OS and criticality."}
            />
            <div className="text-center">
              <h1 className="text-4xl max-w-2xl leading-[56px] font-medium tracking-[-0.88px] sm:text-[44px]">
                The Anamo CDM collector is robust captures the following data
                without Root Access:
              </h1>
              <p className="mt-6 text-[16px] opacity-[0.4] sm:px-20 mb-9">
                The Anamo CDM collector is robust captures the following data
                without Root Access:
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 items-stretch sm:grid-cols-3 gap-x-6 gap-y-10 sm:gap-x-5 sm:gap-y-8">
            {packages.map((item) => (
              <div className="flex items-center gap-3 mb-8">
                <div className=" flex items-center border-[1px] border-[#1a1a1a] rounded-[4px] p-5">
                  <MdOutlineShutterSpeed size="56" />
                </div>
                <div>
                  <p className="text-xl font-medium">{item.title}</p>
                  <p className="text-sm opacity-[0.4]">{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <Partners />

      <section className="text-white mt-24 z-10 relative">
        <div className="md:grid grid-cols-2">
          <div>
            <h1 className="text-[36px] md:text-[44px]  font-medium  opacity-[0.9] md:mb-4">
              Frequently Asked Questions
            </h1>
            <p className="opacity-[0.5] flex items-end mb-8">
              We want to be the greatest help we can be to you. If you have a
              recommendation on how we can better improve our content,
              information delivery, or amplify our service, please contact us
              immediately and we’ll do our best to accommodate!
            </p>
          </div>
        </div>
        <AccordionCard />
      </section>
    </div>
  );
}
