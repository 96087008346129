import React from "react";
import Titlelink from "../components/others/Titlelink";
import teamImage from "../assets/images/team.jpeg";

export default function About() {
  return (
    <div className="bg-black text-white about font-neue">
      <section className="about-hero">
        {/* <Titlelink
          text1="Did you see our new article?"
          text2="Check it out @ Medium"
        /> */}
        <div className="grid grid-cols-2 gap-8">
          <h5 className="text-6xl font-medium opacity-[0.88] capitalize">
            We build <span className="bridges"> bridges</span> between you and
            your servers
          </h5>
          <p className="opacity-[0.5] flex items-end">
            ANAMO is the 1st commercially available CDM software platform, built
            upon a common programming language, which address all major
            components of the DHS CDM Cybersecurity program, that combine these
            specific and critical areas of risk management.
          </p>
        </div>
      </section>
      <section className="bg-about-image h-[562px] bg-cover bg-no-repeat bg-center"></section>
      {/* <section className="about-hero ">
        <div className=" py-16 border-y-[0.5px] border-[#fff] border-opacity-[0.20]">
          <div className="grid grid-cols-2 gap-8 ">
            <h5 className="text-7xl leading-[86px]  font-medium opacity-[0.88] ">
              Together we are <span className="bridges">STRONG</span>
            </h5>
            <div>
              <p className="mb-12">
                ANAMO is the 1st commercially available CDM software platform,
                built upon a common programming language, which address all
                major components of the DHS CDM Cybersecurity program, that
                combine these specific and critical areas of risk management.
              </p>
              <p className="opacity-[0.5]">
                Lorem ipsum dolor sit amet consectetur. Consectetur id vivamus
                urna metus quis tellus ullamcorper elit eget. Nisi orci lectus
                parturient odio quis dui morbi id. Enim arcu viverra varius eu
                et vitae ultrices. Egestas ut in faucibus donec vel. Massa
                adipiscing in lorem tincidunt rhoncus varius pharetra. Sit nunc
                ut tortor dignissim. Orci iaculis sem vitae facilisis
                pellentesque nam gravida lorem aliquet. Lacus non cras dignissim
                nulla nec accumsan sed neque maecenas. Bibendum arcu lacinia
                augue interdum. Leo nisl vestibulum etiam elit pretium facilisi.
                Tortor sed at non integer et a. Nisl vivamus aliquet tincidunt
                massa sed vitae. At justo vulputate elit in. Nisl ut tincidunt
                ultrices faucibus blandit interdum. Amet adipiscing ut sit nulla
                enim neque et libero sed. Luctus diam morbi nulla ultrices mi
                sapien lorem odio. Cras sit turpis vel dolor magna aenean. Eu ac
                molestie diam neque consectetur enim dolor interdum. At vel enim
                hendrerit risus arcu iaculis purus vehicula.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="about-stat ">
        <div className="grid grid-cols-3 gap-24">
          <div>
            <div className="flex gap-9 flex-col">
              <p className="opacity-[0.50]">
                ANAMO is the 1st commercially available CDM software platform,
                built upon a common programming language,
              </p>
              <div className="text-7xl font-medium">100k</div>
            </div>
          </div>
          <div>
            <div className="flex gap-9 flex-col">
              <p className="opacity-[0.50]">
                ANAMO is the 1st commercially available CDM software platform,
                built upon a common programming language,
              </p>
              <div className="text-7xl font-medium">100k</div>
            </div>
          </div>
          <div>
            <div className="flex gap-9 flex-col">
              <p className="opacity-[0.50]">
                ANAMO is the 1st commercially available CDM software platform,
                built upon a common programming language,
              </p>
              <div className="text-7xl font-medium">100k</div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="about-team">
        <h1 className="text-[44px] mt-10 font-medium">Meet The Team</h1>
        {/* <p className="opacity-[0.40]">
          The Anamo CDM collector is robust captures the following data without
          Root Access:
        </p> */}
        <div className="py-12 border-y-[0.5px] border-[#fff] border-opacity-[0.20] mt-8">
          <div className="grid grid-cols-4 gap-10">
            <div className="flex flex-col gap-2">
              <img
                src={teamImage}
                alt="teamImage"
                className="object-cover h-[194px] rounded-lg"
              />
              <p className="text-[28px] leading-8 tracking-tighter font-medium w-1/2">
                Jonathan Goetsch
              </p>
              <p className="opacity-[0.40]">CEO/Founder</p>
            </div>
            <div className="flex flex-col gap-2">
              <img
                src={teamImage}
                alt="teamImage"
                className="object-cover h-[194px] rounded-lg"
              />
              <p className="text-[28px] leading-8 tracking-tighter font-medium w-1/2">
                Jonathan Goetsch
              </p>
              <p className="opacity-[0.40]">CEO/Founder</p>
            </div>
            <div className="flex flex-col gap-2">
              <img
                src={teamImage}
                alt="teamImage"
                className="object-cover h-[194px] rounded-lg"
              />
              <p className="text-[28px] leading-8 tracking-tighter font-medium w-1/2">
                Jonathan Goetsch
              </p>
              <p className="opacity-[0.40]">CEO/Founder</p>
            </div>
            <div className="flex flex-col gap-2">
              <img
                src={teamImage}
                alt="teamImage"
                className="object-cover h-[194px] rounded-lg"
              />
              <p className="text-[28px] leading-8 tracking-tighter font-medium w-1/2">
                Jonathan Goetsch
              </p>
              <p className="opacity-[0.40]">CEO/Founder</p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="about-team">
        <div className="grid grid-cols-2 gap-9">
          <div>
            <h2 className="text-6xl opacity-[0.88] font-medium mb-7">
              Join the Team
            </h2>
            <div className="flex">
              <img
                src={teamImage}
                alt="teamImage"
                className="h-[110px] w-[110px] rounded-full object-cover"
              />
              <img
                src={teamImage}
                alt="teamImage"
                className="h-[110px] w-[110px] rounded-full object-cover ml-[-50px]"
              />
              <img
                src={teamImage}
                alt="teamImage"
                className="h-[110px] w-[110px] rounded-full object-cover ml-[-50px]"
              />
            </div>
          </div>
          <div>
            <p className="opacity-[0.5] mb-6">
              Lorem ipsum dolor sit amet consectetur. Augue magna senectus
              interdum aliquam. Neque volutpat nisl mattis bibendum quisque
              arcu. Enim odio magna elit erat morbi nisi. Suspendisse venenatis
              auctor libero tellus sit elit tincidunt sed sit. Vitae elit mi id
              vulputate eget morbi. Pharetra morbi lectus sit iaculis et.
              Viverra semper facilisis quam nunc pellentesque odio dolor eu
              eros.
            </p>
            <button className="text-[13px] font-medium bg-transparent  bg-red rounded-[4px]  px-12 py-3 ">
              See Open Roles
            </button>
          </div>
        </div>
      </section> */}
    </div>
  );
}
